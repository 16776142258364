<template>
  <div class="image-slider">
    <WidgetSliderSwiper
      :length="slides.length"
      :centeredSlides="true"
      :pagination="true"
    >
      <template
        v-for="(slide, index) in slides"
        :key="index"
        #[`slide-${index}`]
      >
        <div class="image-slider__slide">
          <figure class="image-slider__figure ratio-full-screen m-top-2">
            <WidgetPicture
              :image="{
                landscape: slide.landscape,
                portrait: slide.portrait,
              }"
              :alt="slide.alt"
            />
          </figure>
        </div>
      </template>
    </WidgetSliderSwiper>
  </div>
</template>

<script>
export default {
  name: 'WidgetImageSlider',
}
</script>

<script setup>
const props = defineProps({
  landscape: {
    type: Array,
    default: () => [],
  },
  portrait: {
    type: Array,
    default: () => [],
  },
  alt: {
    type: String,
    default: '',
  },
})

const slides = computed(() => {
  return props.landscape.map((image, index) => {
    const portraitImage = props.portrait[index]
      ? props.portrait[index].responsiveImage
      : null

    return {
      landscape: image.responsiveImage,
      portrait: portraitImage,
      alt: props.alt,
    }
  })
})
</script>

<style lang="scss">
@import './style.scss';
</style>
