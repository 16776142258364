<template>
  <swiper
    class="slider-swiper"
    :class="{
      '--no-padding-bottom': props.length <= 1,
    }"
    :navigation="{
      nextEl: nextButton,
      prevEl: prevButton,
    }"
    :modules="[Pagination, A11y, Navigation]"
    :slides-per-view="props.options?.slidesPerView || 'auto'"
    :pagination="{ clickable: false }"
    :centeredSlides="props.options?.centeredSlides || false"
    :lazy="true"
    @swiper="onSwiper"
  >
    <swiper-slide v-for="index in props.length" :key="index">
      <div class="slider-swiper__slide__wrap">
        <slot :name="`slide-${index - 1}`" />
      </div>
    </swiper-slide>

    <!-- Navigation-->
    <div class="swiper-buttons" v-if="props.length > 1">
      <button
        class="swiper-button-prev button-circle-1 is-blue"
        ref="prevButton"
        aria-label="Button Prev"
      ></button>
      <button
        class="swiper-button-next button-circle-1 is-blue"
        ref="nextButton"
        aria-label="Button Next"
      ></button>
    </div>
  </swiper>
</template>

<script>
export default {
  name: 'WidgetSliderSwiper',
}
</script>

<script setup>
import { Pagination, A11y, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

const props = defineProps({
  length: {
    type: Number,
    default: 0,
  },
  options: {
    type: Object,
  },
})

const slider = ref(null)
const nextButton = ref(null)
const prevButton = ref(null)

const onSwiper = (swiper) => {
  slider.value = swiper
}
</script>

<style lang="scss">
@import './style.scss';
</style>
