<template>
  <section class="wrap" ref="stickyWrap">
    <h2
      class="text-style-center title-s m-bottom-3"
      v-if="title"
      v-html="title"
    />

    <article
      v-for="(section, index) in props.sections"
      :key="index"
      class="content content--sticky content--card"
    >
      <figure class="content__figure" v-if="section.previewImage">
        <WidgetPicture
          :image="{
            custom: section.previewImage.responsiveImage,
          }"
          :alt="section.pageTitle"
        />
      </figure>
      <header class="content__header">
        <h2 class="title text-style-uppercase" v-html="section.pageTitle" />
        <p class="text-m m-top-8px">
          {{ section.description }}
        </p>

        <nuxt-link
          v-if="activity === 'section'"
          :to="
            localePath({
              name: 'activity-parent',
              params: {
                parent: section.slug,
              },
            })
          "
          class="button-round-1 --orange m-top-24px"
        >
          <span>{{ $t('navigation.discover-activity') }}</span>
        </nuxt-link>
        <nuxt-link
          v-if="activity === 'subsection'"
          :to="
            localePath({
              name: 'activity-parent-slug',
              params: { parent: parent, slug: section.slug },
            })
          "
          class="button-round-1 --orange m-top-24px"
          >{{ $t('navigation.discover-activity') }}</nuxt-link
        >
      </header>
    </article>
  </section>
</template>

<script>
export default {
  name: 'WidgetStickySections',
}
</script>

<script setup>
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const props = defineProps({
  sections: Array,
  activity: String,
  parent: String,
  title: String,
})

const stickyWrap = ref(null)

onMounted(() => {
  const contentElements = [
    ...stickyWrap.value.querySelectorAll('.content--sticky'),
  ]
  const totalContentElements = contentElements.length

  contentElements.forEach((el, position) => {
    const isLast = position === totalContentElements - 1

    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: 'center center',
          end: '+=100%',
          scrub: true,
        },
      })
      .to(
        el,
        {
          ease: 'none',
          scale: 0.6,
          opacity: 0,
          yPercent: isLast ? 125 : 0,
        },
        0
      )
  })

  window.dispatchEvent(new Event('resize'))
})
</script>

<style lang="scss">
@import './style.scss';
</style>
