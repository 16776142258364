<template>
  <header class="site-header p-top-3">
    <div class="site-header__content row-1">
      <h1
        class="text-style-center title-m text-style-uppercase"
        v-html="props.page.pageTitle"
      />

      <WidgetButtonsStripe
        v-if="store.state?.events?.length && props.showEvents"
        :events="store.state?.events"
        class="m-top-4"
      />

      <div
        class="buttons-stripe__button m-top-4"
        v-if="props.page.downloadLabel && props.page.downloadFile.url"
      >
        <nuxt-link
          :to="props.page.downloadFile.url"
          class="button-round-1 --violet"
        >
          <span>{{ props.page.downloadLabel }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 24 24"
            aria-labelledby="downloadIconTitle"
            stroke="#2329D6"
            stroke-width="1.5"
            stroke-linecap="round"
            fill="none"
            color="#2329D6"
          >
            <path d="M12 3v13M7 12l5 5 5-5M20 21H4" />
          </svg>
        </nuxt-link>
      </div>
    </div>
    <WidgetImageSlider
      :landscape="props.page.landscape"
      :portrait="props.page.portrait"
      :alt="props.page.pageTitle"
    />
  </header>
</template>

<script>
export default {
  name: 'WidgetHeader',
}
</script>

<script setup>
import { useStore } from '@/store/index.js'

const props = defineProps({
  page: Object,
  showEvents: false,
})

const store = useStore()
</script>

<style lang="scss">
@import './style.scss';
</style>
