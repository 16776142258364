<template>
  <picture>
    <source
      v-if="props.image?.landscape?.webpSrcSet"
      media="(orientation: landscape)"
      :srcset="props.image.landscape.webpSrcSet"
    />

    <source
      v-if="props.image?.portrait?.webpSrcSet"
      media="(orientation: portrait)"
      :srcset="props.image.portrait.webpSrcSet"
    />

    <source
      v-if="props.image?.custom?.webpSrcSet"
      :srcset="props.image.custom.webpSrcSet"
    />

    <img
      :src="src"
      :alt="alt"
      :loading="props.lazyLoad ? 'lazy' : 'auto'"
      :width="sizes?.width"
      :height="sizes?.height"
    />
  </picture>
</template>

<script>
export default {
  name: 'WidgetPicture',
}
</script>

<script setup>
const props = defineProps({
  image: Object,
  lazyLoad: {
    type: String,
    default: true,
  },
  alt: {
    type: String,
    default: '',
  },
})

const alt = computed(() => {
  return props?.alt?.replace(/(<([^>]+)>)/gi, '')
})

const src = computed(() => {
  if (props.image?.landscape?.url) {
    return props.image?.landscape?.url
  } else if (props.image?.portrait?.url) {
    return props.image?.portrait?.url
  } else if (props.image?.custom?.url) {
    return props.image?.custom?.url
  } else {
    return undefined
  }
})

const sizes = computed(() => {
  if (props.image?.landscape) {
    return {
      width: props.image.landscape.width,
      height: props.image.landscape.height,
    }
  } else if (props.image?.portrait) {
    return {
      width: props.image.portrait.width,
      height: props.image.portrait.height,
    }
  } else if (props.image?.custom) {
    return {
      width: props.image.custom.width,
      height: props.image.custom.height,
    }
  } else {
    return undefined
  }
})
</script>
